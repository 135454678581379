import React from 'react';
import './App.css';
import MapContainer from './components/MapContainer/mapContainer';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#17427F'
    }
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <MapContainer/>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
