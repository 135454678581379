import React, { Component } from 'react';
import L from 'leaflet';
import { Map, TileLayer, LayersControl, Marker, AttributionControl } from 'react-leaflet';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import PauseIcon from '@material-ui/icons/Pause';
import moment from 'moment';
import 'moment/locale/de';

import cssModule from './mapRadarComponent.module.css';

const { BaseLayer } = LayersControl;

let DefaultIcon = L.icon({
    iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAADW0lEQVRYR+2WSWgUQRSG/9cTPOhJ0EP0omgyPQouOD1JVFBQNLihguIKKqKI6Y5e4jHxqJdo90QUET24gCsoCCpu4JbpxKCgqRHR4CEqBs+iM/2kJ5NxtprqGBEP9mEYuv7/ff1eVb0qwl946C8w8B8yrCr/G+UK1x2boaV/zGRos0GoB9BAxI+Y6RETnleFtJ5XT/e+rZSaNJNw1FlFhBYA85S1IbocIq/1Vaf1upy2LCQSs08x0w5l8CIBMw4nu8wDxb4SiG7EvwA8briAPP0n4ZrV+f4CiG44FwBsGAEga+UO4VpNQ3FykEgsvoaZr44cMBiBNG7o7bSeZf77P1Pn2FOqNHoJYPSfggC4J1xzUQ6iG46fwRo1gPoA7gNQA2CiUs9oE13mQcK6iyG97/MXAGMrmZiwPpkwLw1pItH4PiZuV3huJhPmctJj8QYwP6m4mbxRE3q7d38s1kTmnKhm7Xu/3MsDwrXGU9hwmgk4IhVmU5aN61GnFYQ22bhHPIt0I34W4M1SiOZFRWdztxxibwTR+QrZ7CLdcK4AWCsThaq0mkq9qTbmrNYY16QQgkXhqHMo26PK6wiWSJiOLEg46rQQ4ZC0XIxlFInFdzLzyYrLMZSaLJ7t95duwaPXt09Cuup9Ja9fCdKjzkIQ7qvWPDPmJ7vMx0M6PRZfAeYbKp9wTcrseN1wkgBqVQYAHwC8y2onqPQEXOp1zfUZiKquqmCycQa2Jl3zbAZSU98RCaW9sgfO7wJ8H6cwMdlj9ue6sG7Y1wFaOZKghV6+IVxrlf/uF6SuYwk879Yfg2jaUtG593YBZHABKHZ/4C+gc8Jt2jIkLzwZAzTLQByiuSLR9LQsZDAb+yhAVqBgZUVsC9dqzh8quUhMnW2PD4XoIREiwwUxozed5gVveyz/fMo9Za9EeszZBMa54UJA2CwSZklHll7udMM+DdC24CA+I1xrezm9FJI99e4AmB4A9DyV4sbiMkknPj9gwCb4DeBG4VoPZR+jvNWrjlcm7EkmzOOVslVCspv0OsClLYfhiC5TudwDQabX2dPSHvnzk2vvDL5bPeZr44MHbSnVnAWCZLKJ5l8YeMAjLH6TsF6oACW9S2UIG3GLwEdl++G3J77Y6C8E/+qp+qD88Z+frixbkg5fQgAAAABJRU5ErkJggg==',
    iconSize: [25, 42],
    iconAnchor: [12, 42]
});

L.Marker.prototype.options.icon = DefaultIcon;

class mapRadarComponent extends Component {

    componentWillUnmount() {
        this.stopSlide(this.state.slider_value);
    }

    home_url;
    radar_layers;
    radar_folders;
    slider_min;
    stop_sliding;
    map_object;
    next_layer;
    last_layer;
    user_time;
    legend_type;

    constructor(props) {
        super(props);
        this.state = {
            lat: 54.048433,
            lng: 9.230591,
            zoom: 6,
            slider_value: 0,
            current_layer: { datetime: '', id: 0 },
            slider_max: 100,
            kartentyp: 'Radar',
            paused: false,
            stopped: false,
            mapChanged: false,
            pausedSliderValue: 0,
            sliderMoved: false,
            language: "de",
        }
        this.mapRef = React.createRef();
        this.home_url = "https://radar.qmet.de/";
        this.radar_layers = [];
        this.radar_folders = [];
        this.slider_min = 0;
        this.stop_sliding = false;
        this.map_object = null;
        this.next_layer = { datetime: '', id: 0 };
        this.last_layer = null;
        this.user_time = '';
        this.mapChanged = false;
        this.selectedFolder = '';
        this.selectedSub = '';
        this.refStopped = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        try{
        return {
            lat: nextProps.propsLon,
            lng: nextProps.propsLat,
            zoom: nextProps.propsZoom,
            kartentyp: nextProps.kartentyp,
            mapChanged: nextProps.mapChanged,
        };
    }catch(error){}
    }

    async shouldComponentUpdate(nextProps) {
        try{
        if (this.state.lat !== nextProps.propsLon || this.state.lng !== nextProps.propsLat || this.state.kartentyp !== nextProps.kartentyp) {
            this.setState({ kartentyp: nextProps.kartentyp }, () => { });
            this.mapChanged = this.state.mapChanged;
            await this.Sleep(1001);
            this.mapChanged = !this.state.mapChanged;
            await this.getRadarFolder();
            this.CreateMapObject();
            await this.getRadarLayers();
            await this.setRadarLayers();
        }
        return true;
    }catch(error){}
    }

    async componentDidMount() {
        try{
            this.setState({ language: this.props.language.toString()});
            await this.getRadarFolder();
            this.CreateMapObject();
            await this.getRadarLayers();
            await this.setRadarLayers();
            this.user_time = moment((moment().format('YYMMDDhhmm')), 'YYMMDDhhmm').local().format('LLLL');
    }catch(error){}
    }

    getRadarFolder = async () => {
        this.radar_layers = [];
        this.radar_folders = [];
        try {
            let url_radar = `https://radar.qmet.de:8080/`;
            let response = await fetch(url_radar);
            let data = await response.json();
            this.radar_folders = data;
        }
        catch (message) {
            console.log('fetching Radar folder', message);
        }
    }

    CreateMapObject = () => {
        try{
        this.map_object = this.mapRef.current.leafletElement;
        this.map_object.dragging.enable();
        }catch(error){console.log('202103231649', error)}
    }

    setTemperaturLegend = () => {
        /*
mm	dBz	R	G	B	Alpha
0,02	1	255	255	255	0
0,02	2	255	255	255	0
0,02	3	135	255	254	0
0,02	4	152	240	255	240
0,05	5	152	254	254	240
0,1	10	50	254	254	240
0,15	14	0	202	202	240
0,2	19	0	152	52	240
0,75	23	76	190	26	240
2	28	152	204	0	240
3	32	204	230	0	240
7,5	37	254	254	0	240
17	42	254	196	0	240
34	46	254	136	0	240
62	50	254	0	0	240
150	55	180	0	0	240
288	60	72	72	254	240
421	65	0	0	202	240
> 500	75	152	0	152	240
> 500	85	254	50	254	240
> 500	86	254	50	120	240

         */

       try{
        return (
            <div className={cssModule.Info}>
                <div className={cssModule.InfoSection}>
                    {/*<p>Niedersch-<br/>lagsrate<br/>in mm/h</p>*/}
                    <p className={cssModule.LegendHead}>Nieder-<br />schlags-<br/>rate<br/>in mm/h</p>
                    {/*<div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(255,255,255,0)' }}></i><span>0,02 l/m<sup>2</sup></span></div>1
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(255,255,255,0)' }}></i><span>0,02 l/m<sup>2</sup></span></div>2
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(135,255,254,0)' }}></i><span>0,02 l/m<sup>2</sup></span></div>3*/}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(152,240,255,240)' }}></i><span>0.02</span></div>{/* 4 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(152,254,254,240)' }}></i><span>0.05</span></div>{/* 5 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(50,254,254,240)' }}></i><span>0.1</span></div>{/* 10 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(0,202,202,240)' }}></i><span>0.15</span></div>{/* 14 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(0,152,52,240)' }}></i><span>0.2</span></div>{/* 19 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(76,190,26,240)' }}></i><span>0.75</span></div>{/* 23 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(152,204,0,240)' }}></i><span>2</span></div>{/* 28 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(204,230,0,240)' }}></i><span>3</span></div>{/* 32 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,254,0,240)' }}></i><span>7.5</span></div>{/* 37 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,196,0,240)' }}></i><span>17</span></div>{/* 42 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,36,0,240)' }}></i><span>34</span></div>{/* 46 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,0,0,240)' }}></i><span>62</span></div>{/* 50 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(180,0,0,240)' }}></i><span>150</span></div>{/* 55 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(72,72,254,240)' }}></i><span>288</span></div>{/* 60 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(0,0,202,240)' }}></i><span>421</span></div>{/* 65 */}
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(152,0,152,240)' }}></i><span>&#62; 500</span></div>{/* 75 */}
                    {/*<div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,50,254,240)' }}></i><span>&#62; 500 l/m<sup>2</sup></span></div>85
                    <div className={cssModule.Legend} style={{clear:'both'}}><i style={{ 'backgroundColor': 'rgba(254,50,120,240)' }}></i><span>&#62; 500 l/m<sup>2</sup></span></div>86*/}
                </div>
                {/*<div className={cssModule.InfoSectionUnit}>
                    <FormControl component="fieldset">
                        <span>Unit</span>
                        <RadioGroup aria-label="unit" name="unit" value={this.state.unit} onChange={this.handleUnitChange}>
                            <FormControlLabel value="metric" control={<Radio size="small" color="primary"/>} label="°C"/>
                            <FormControlLabel value="imperial" control={<Radio size="small"  color="primary"/>} label="°F" />
                        </RadioGroup>
                    </FormControl>
                </div>*/}
            </div>
        )
    }catch(error){}
    }

    getRadarLayers = async () => {
        this.radar_layers = [];
        try {

            for (let radarFolder of this.radar_folders) {
                let new_radar_layer_url = this.home_url + 'radar/' + radarFolder.folder + "/{z}/{x}/{y}.png";
                let new_radar_layer = window.L.tileLayer(new_radar_layer_url, { tms: true, maxZoom: 18, maxNativeZoom: 10, minZoom: 2, minNativeZoom: 5, zIndex: 5 });
                let single_layer_object = {};
                let time_str = radarFolder.datetime;
                single_layer_object['id'] = radarFolder.folder;
                single_layer_object['datetime'] = moment.utc(time_str, "YYMMDDhhmm").local().format('LLLL');
                single_layer_object['datetime_en'] = moment.utc(time_str, "YYMMDDhhmm")._d.toLocaleDateString('en-GB',{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'});
                //console.log(moment.utc(time_str, "YYMMDDhhmm").locale("en").format('LLLL'))
                single_layer_object['standard_date'] = moment.utc(time_str, "YYMMDDhhmm");
                single_layer_object['layer'] = new_radar_layer;
                this.radar_layers.push(single_layer_object);
            }
        } catch (error) { console.log('202010011256', error); }
    }

    setRadarLayers = async () => {
        this.setState({ paused: false, stopped: false, sliderMoved:false }, () => { });
        this.stop_sliding = false;
        this.setState({ slider_max: parseInt(this.radar_folders.length) - 1 }, () => { });

        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }
        for (let index = 0; index < this.radar_layers.length; index++) {
            if (this.mapChanged === true) {
                break;
            }
            if (this.refStopped === true) {
                let x = parseInt(index);
                if (x > -1) {
                    this.map_object.removeLayer(this.radar_layers[x].layer);
                }
                this.refStopped = false;
                break;
            }
            if (this.stop_sliding === false) {
                try {
                    this.setState({ current_layer: this.radar_layers[parseInt(index)] }, () => { });
                } catch (message) { console.log('202010011152', message); }

                if (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) {
                    try {
                        let x = parseInt(index) + 1;
                        if (this.radar_layers[x]) {
                            this.radar_layers[x].layer.setOpacity(0);
                            this.radar_layers[x].layer.addTo(this.map_object);
                            //NOTE::wait until available the corner bounds for radar map
                            //await this.setMapLoaded(this.radar_layers[x].layer);

                            //await this.setMapLoaded(this.radar_layers[x].layer);
                            if (this.refStopped === true) {
                                this.refStopped = false;
                                break;
                            }
                        }
                    } catch (message) { console.log('202010011154', message); }
                }
                else { await this.Sleep(500); }

                try {
                    this.radar_layers[parseInt(index)].layer.setOpacity(.7);
                    let x = parseInt(index) - 1;
                    if (x > -1) {
                        this.map_object.removeLayer(this.radar_layers[x].layer);
                    }

                    if (index === 0) {
                        this.radar_layers[parseInt(index)].layer.addTo(this.map_object);
                        //await this.setMapLoaded(this.radar_layers[parseInt(index)].layer);
                        //this function check weather the current tile layer is completely loaded.
                    }
                    this.setState({ slider_value: this.radar_layers[parseInt(index)].id }, () => { });
                    this.last_layer = this.radar_layers[parseInt(index)].layer;
                    await this.Sleep(1000);
                } catch (error) {
                    console.log('202010011152', error);
                }
                this.enableMapMove();
            }
        }
    }

    setRadarLayersPlay = async (startIndex) => {
        let valStartIndex = parseInt(startIndex) + 1;
        this.setState({ paused: false, stopped: false }, () => { })
        this.stop_sliding = false;
        this.setState({ slider_max: parseInt(this.radar_folders.length) - 1 }, () => { });

        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }

        for (let index = valStartIndex; index < this.radar_layers.length; index++) {
            if (this.mapChanged === true) {
                break;
            }
            if (this.stop_sliding === false) {
                try {
                    this.setState({ current_layer: this.radar_layers[parseInt(index)] }, () => { });
                } catch (message) { console.log('202010011152', message); }

                if (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) {
                    try {
                        let x = parseInt(index) + 1;
                        if (this.radar_layers[x]) {
                            this.radar_layers[x].layer.setOpacity(0);
                            this.radar_layers[x].layer.addTo(this.map_object);
                            //NOTE::wait until available the corner bounds for radar map
                            //await this.setMapLoaded(this.radar_layers[x].layer);
                            //this function check weather the current tile layer is completely loaded.
                        }
                    } catch (message) { console.log('202010011154', message); }
                }
                else { await this.Sleep(500); }
                try {
                    let x = parseInt(index) - 1;
                    if (x > -1) {
                        this.map_object.removeLayer(this.radar_layers[x].layer);
                    }
                    this.radar_layers[parseInt(index)].layer.setOpacity(.7);
                    this.radar_layers[parseInt(index)].layer.addTo(this.map_object);
                    this.setState({ slider_value: this.radar_layers[parseInt(index)].id }, () => { });
                    this.last_layer = this.radar_layers[parseInt(index)].layer;
                    await this.Sleep(1000);
                } catch (error) {
                    console.log('202010011152', error);
                }
                this.enableMapMove();
            }
        }
    }

    /*setMapLoaded = async (argLayer) => {
        return new Promise((resolve, reject) => {
            argLayer.on("load", async function () {
                console.log('loaded123');
                resolve(argLayer);
            });
        });
    }*/

    setMapLoaded = async (argLayer) => {
        return new Promise((resolve, reject) => {
            if (this.refStopped === true) {
                resolve(argLayer);
            } else {
                argLayer.on("load", async function () {
                    resolve(argLayer);
                });
            }
        });
    }

    Sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    selectTimestep = async (varSlideValue) => {
        try {
            //let argSlideValue = varSlideValue -1;
            this.stop_sliding = true;
            this.setState({ current_layer: this.radar_layers[parseInt(varSlideValue)] }, () => { });
            this.radar_layers[parseInt(varSlideValue)].layer.setOpacity(.7);
            this.radar_layers[parseInt(varSlideValue)].layer.addTo(this.map_object);
            if (parseInt(varSlideValue) !== parseInt(this.state.current_layer.id)) {
                this.map_object.removeLayer(this.last_layer);
            }
            this.last_layer = this.radar_layers[parseInt(varSlideValue)].layer;
            this.enableMapMove();
        } catch (error) {
            console.log('202010060241', error);
        }
    }

    enableMapMove = () => {
        try{
        this.map_object.dragging.enable();
    }catch(error){}
    }

    disableMapmove = () => {
        try{
        this.map_object.dragging.disable();
        }catch(error){}
    }

    sliderValueHandler = async (event) => {
        try{
        if (this.last_layer) {
            this.map_object.removeLayer(this.last_layer);
        }
        this.setState({ slider_value: event.target.value, stopped: true, sliderMoved: true, pausedSliderValue: event.target.value}, () => { });
        await this.selectTimestep(event.target.value);
    }catch(error){}
    };

    sliderMoved = async (event) => {
        try{
        this.setState({ sliderMoved: true, pausedSliderValue: this.state.slider_value}, () => { });
    }catch(error){}
    }

    stopSlide = (argVal) => {
        try{
        if (this.stop_sliding === true) {
            this.selectTimestep(argVal);
        }
        else {
            this.stop_sliding = true;
        }
    }catch(error){}
    }

    pauseSlideAnimation = async () => {
        try{
        this.setState({ paused: true, pausedSliderValue: this.state.slider_value }, () => { });
        this.stop_sliding = true;
        await this.Sleep(500);
    }catch(error){}
    }

    stopSlideAnimation = async () => {
        try {
            this.map_object.removeLayer(this.radar_layers[parseInt(this.state.slider_value)].layer);
            this.map_object.removeLayer(this.radar_layers[parseInt(this.state.slider_value) + 1].layer);
            this.stop_sliding = true;
            this.refStopped = true;
            await this.Sleep(750);
            this.setState({ stopped: true }, () => { });
        } catch (error) { console.log('202010161214', error) }
    }

    playSlideAnimation = async () => {
        try{
        await this.Sleep(500);
        this.setState({ paused: false, sliderMoved: false }, () => { });
        this.stop_sliding = false;
        this.setRadarLayersPlay(this.state.pausedSliderValue);
    }catch(error){}
    }

    refreshSlideAnimation = async () => {
        try{
        this.refStopped = false;
        await this.Sleep(500);
        this.setRadarLayers();
        this.setState({ pausedSliderValue: 0});
    }catch(error){}
    }

    handleMoveEnd = () => {
        /*localStorage.setItem('coordsData', JSON.stringify({
            commonLat : Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonLng:  Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonZoom: Number(this.mapRef.current.leafletElement.getZoom())}));*/
            try{
        this.props.updateCoords(
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getZoom())
        );
    }catch(error){}
    }

    handleZoomEnd = () => {
        /*localStorage.setItem('coordsData', JSON.stringify({
            commonLat : Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonLng:  Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            commonZoom: Number(this.mapRef.current.leafletElement.getZoom())}));*/
            try{
        this.props.updateCoords(
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lat.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getBounds().getCenter().lng.toLocaleString('en-US', { minimumFractionDigits: 6 })),
            Number(this.mapRef.current.leafletElement.getZoom())
        );
    }catch(error){}
}

    render() {

        this.legend_type = this.setTemperaturLegend();
        const position = [this.state.lng, this.state.lat];
        let userDate = new Date();
        let layerDate = new Date(this.state.current_layer.standard_date);
        const markerPosition = [this.props.userLat, this.props.userLng];

        return (
            <div>
                <div className = {cssModule.MapControlModule}>
                        {
                            (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                            <div className={cssModule.ControlTextContainer}>
                                <span className={cssModule.ControlText}>{layerDate <= userDate ? (this.state.language === 'en' ? 'Past' : 'Vergangenheit') : null}</span>
                                <span className={cssModule.ControlText}>{layerDate > userDate ? (this.state.language === 'en' ? 'Forecast' : 'Vorhersage') : null}</span>
                                <span className={cssModule.ControlTextTime}>{this.state.language === 'en' ? this.state.current_layer.datetime_en : this.state.current_layer.datetime}</span>
                            </div>
                            : null
                        }
                    <div className={cssModule.ControlButtonContainer}>
                        <div className={cssModule.ButtonIcons}>
                            {
                                ((this.state.paused && !this.state.stopped) || this.state.sliderMoved) ?
                                    <span>
                                        <PlayArrowIcon fontSize='large' onClick={this.playSlideAnimation} color='primary' />
                                    </span> : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.paused && !this.state.stopped) ?
                                    <span>
                                        <PauseIcon fontSize='large' onClick={this.pauseSlideAnimation} color='primary'/>
                                    </span>
                                    : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.stopped) ?
                                    <span>
                                        <StopIcon fontSize='large' onClick={this.stopSlideAnimation} color='primary'/>
                                    </span> : null
                            }
                            {
                                ((parseInt(this.state.current_layer.id) === parseInt(this.state.slider_max)) || this.state.stopped) ?
                                    <span>
                                        <RefreshOutlinedIcon fontSize='large' onClick={this.refreshSlideAnimation} color='primary'/>
                                    </span>
                                    : null
                            }
                        </div>
                        <div className={cssModule.SliderControl}>
                            <input type='range'
                                id='IdSlider'
                                className={cssModule.SliderSelector}
                                min={0}
                                max={this.state.slider_max}
                                value={this.state.slider_value}
                                onInput={this.sliderMoved}
                                onChange={this.sliderValueHandler}
                                onMouseDown={this.disableMapmove}
                                onMouseMove={this.disableMapmove}
                                onMouseUp={this.enableMapMove}
                                onMouseOut={this.enableMapMove} />
                        </div>
                    </div>
                </div>

                {/*<div className={cssModule.DetailsModule}>
                    <div className={cssModule.SliderModule}>
                        <div className={cssModule.DateModule}>
                            {
                                (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                                    <span className={cssModule.MapTime}>
                                        <div className={cssModule.TextBold}>{layerDate <= userDate ? 'Vergangenheit' : null}</div>
                                        <div className={cssModule.TextBold}>{layerDate > userDate ? 'Vorhersage' : null}</div>
                                    </span>
                                    : null
                            }
                            {
                                (parseInt(this.state.current_layer.id) <= parseInt(this.state.slider_max)) ?
                                    <div className={cssModule.TimeDisplay}>{this.state.current_layer.datetime}</div>
                                : null
                            }
                        </div>

                        <input type='range'
                            id='IdSlider'
                            className={cssModule.SliderSelector}
                            min={0}
                            max={this.state.slider_max}
                            value={this.state.slider_value}
                            onInput={this.sliderMoved}
                            onChange={this.sliderValueHandler}
                            onMouseDown={this.disableMapmove}
                            onMouseMove={this.disableMapmove}/>

                    </div>
                    <div className={cssModule.ButtonIcons}>
                        {
                            ((this.state.paused && !this.state.stopped) || this.state.sliderMoved) ?
                                <span>
                                    <PlayArrowIcon fontSize='large' onClick={this.playSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span> : null
                        }
                        {
                            ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.paused && !this.state.stopped) ?
                                <span>
                                    <PauseIcon fontSize='large' onClick={this.pauseSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span>
                                : null
                        }
                        {
                            ((parseInt(this.state.current_layer.id) < parseInt(this.state.slider_max)) && !this.state.stopped) ?
                                <span>
                                    <StopIcon fontSize='large' onClick={this.stopSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span> : null
                        }

                        {
                            ((parseInt(this.state.current_layer.id) === parseInt(this.state.slider_max)) || this.state.stopped) ?
                                <span>
                                    <RefreshOutlinedIcon fontSize='large' onClick={this.refreshSlideAnimation} color='primary' colorprimary='#17427F' />
                                </span>
                                : null
                        }
                    </div>
                </div>*/}

                {this.legend_type}

                <Map
                    ref={this.mapRef}
                    style={{ height: '100vh' }}
                    center={position}
                    zoom={parseInt(this.state.zoom) < 6 ? 6 : this.state.zoom}
                    zoomControl={false}
                    minZoom={6}
                    maxZoom={10}
                    maxBounds={L.latLngBounds(L.latLng(55.5, 16.0), L.latLng(46.5, 5.0))}
                    onmoveend={this.handleMoveEnd}
                    onzoomend={this.handleZoomEnd}
                    attributionControl={false}>
                    <TileLayer
                        url="https://radar.qmet.de/tileLayer/{z}/{x}/{y}.png"
                        zIndex='1'
                        attribution='<a target="_blank" rel="nofollow noopener" href="https://leafletjs.com/">Leaflet</a> | &copy; <a target="_blank" rel="nofollow noopener" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <AttributionControl position="bottomright" prefix={false} />
                    <Marker position={markerPosition} draggable={false}></Marker>
                </Map>
            </div>
        );
    }
}

export default mapRadarComponent;
